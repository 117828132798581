#dropdown-favs-container{
    display: flex !important;
    justify-content: right !important;
}

#dropdown-favs{
    margin-right: 25px;
    color: #2A407E !important;
}

#dropdown-favs .dropdown-item {
    color: black !important;
}