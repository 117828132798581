.container-welcome{
    margin: -30px -30px;
    padding: 0px;
    right: 0;
    left: 0;
}

.bg-welcome {
    min-height: 94vh;    
    background: no-repeat top center url('../../../assets/welcome_page.jpg');
    background-size: cover;
    padding: 0px;  
}

.div-welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35rem;
}