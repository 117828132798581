body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-main: #2A407E;
  --primary-dark: #1D2C58;
  --primary-light: #546697;
  --secondary-main: #2A407E;
  --secondary-dark: #1D2C58;
  --secondary-light: #546697;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  color: #ffffff !important;
  text-decoration: none !important;
}

a:hover {
  color: #ffffff !important;
  text-decoration: none;
  cursor: pointer !important;
}

.pages-title {
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 48px !important;
}

/* todas las paginaciones centradas */
.MuiPagination-ul {
  justify-content: center !important;
}