/* add collection */
.add-collection, .add-dtc {
    background: #e5e5e5;
    border-radius: 15px;
}

.add-dtc {
    min-height: 340px;
    max-height: 350px;
}

.add-collection {
    height: 320px;
    min-width: 350px;
    max-width: 365px;
    margin-bottom: 30px;
}

.add-collection:hover, .add-dtc:hover {
    background: #d3d3d3;
}

.add-collection p, .add-dtc p {
    margin: 0;
    font-size: 20px;
    color: #40415c;
}

.add-collection svg, .add-dtc svg {
    background: #40415c;
    color: white;
    padding: 10px;
    border-radius: 50%;
    margin: 0 0 0.5rem 0;
    width: 2em;
    height: 2em;
}

.add-collection button:hover, .add-dtc button:hover {
    background-color: rgba(0, 0, 0, 0);
}