.custom-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 250px;
    border: 2px dashed rgba(175, 175, 175, 0.94);
    border-radius: 5%;
    cursor: pointer;
    margin-top: 1%;
}

.custom-banner-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    border: 2px dashed rgba(175, 175, 175, 0.94);
    border-radius: 13px;
    cursor: pointer;
    margin-top: 1%;
}

input[type="file"] {
    display: none;
}