.bg-login {
    /* background: no-repeat center center fixed url('../../../assets/Fondo.svg'); */
    background: no-repeat top center fixed url('../../assets/welcome_page.jpg');
    background-size: cover;
    min-height: 100vh;    
    height: 100%;
    overflow: hidden;
}

.btn-login {
    display: flex;
    justify-content: center;
    margin-top: -2.5rem;
}

.loginBox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.login:hover {
    -webkit-transition: background-color, font-size 0.2s ease-out;
    -moz-transition: background-color, font-size  0.2s ease-out;
    -o-transition: background-color, font-size  0.2s ease-out;
    transition: background-color, font-size  0.2s ease-out;
}