.wrong-network {
    margin-top: 60px;
    z-index: 1250;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #ff9800;
    padding: 5px;
}