.modal-properties, .modal-properties {
    padding: 14px 21px;
    display: grid;
    align-content: space-between;
}

.metamask-modal-properties {
    width: clamp(200px, 90vw, 580px);
    height: clamp(200px, 50vh, 380px);
}

.confirmation-modal-properties, .waiting-modal-properties {
    width: clamp(200px, 90vw, 450px);
    height: clamp(200px, 50vh, 300px);
}

.info-modal {
    padding: 14px 21px;
    width: clamp(300px, 90%, 600px);
    height: clamp(206px, 95%, 1200px);
    display: grid;
    align-content: baseline;
    overflow-y: scroll !important;
}

.info-modal::-webkit-scrollbar {
    display: none;
}

.link, .link:visited, link:active, .link:link {
    color: var(--secondary-main);
    text-decoration: none;
    display: inline-block;
}


.modal-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    text-align: center;
}

.modal-text {
    text-align: center;
    width: 95%;
}

.divider {
    background-color: var(--primary-light);
}

.button-section {
    display: flex;
    justify-content: flex-end;
    background: var(--primary-main);
}

.check-button-section {
    display: flex;
    justify-content: space-between;
    background: var(--primary-main);
}